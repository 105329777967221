import { createStyles, Grid, makeStyles } from '@material-ui/core';

import styled from 'styled-components';
import BayTreeIcon from '../../../asset/images/icon/BayTreeIcon';
import BattleContractCharacter from '../../../components/elements/contract/BattleContractCharacter';
import BattleContractCoinIcon from '../../../components/elements/contract/BattleContractCoinIcon';
import BattleContractFireIcon from '../../../components/elements/contract/BattleContractFireIcon';
import BattleContractFrame from '../../../components/elements/contract/BattleContractFrame';
import BattleContractPeriodIcon from '../../../components/elements/contract/BattleContractPeriodIcon';
import BattleContractStatusText from '../../../components/elements/contract/BattleContractStatusText';
import { BattleContractInfo } from '../../../model/Battle';
import { timeReverseCalculator, translateTextDate } from '../../../module/date';
import BattleProfileImg from './BattleProfileImg';

interface Props {
  battleInfo: BattleContractInfo;
}

export default function ResultContract({ battleInfo }: Props) {
  const classes = useStyles();
  const {
    battleEndAt,
    battleStartAt,
    battleName,
    battleStatus,
    battleMoney,
    battlePenalty,
    battleInviteCode,
  } = battleInfo.battleContractMain;
  const members = battleInfo.battleContractContent;
  const player1 = {
    ...members?.[0],
    weightChange: parseFloat(
      Number(members?.[0]?.weightResult - members?.[0]?.weightStart).toFixed(1),
    ),
  };
  const player2 = {
    ...members?.[1],
    weightChange: parseFloat(
      Number(members?.[1]?.weightResult - members?.[1]?.weightStart).toFixed(1),
    ),
  };
  const winStatus =
    (members[0].battleResult === 2 && members[1].battleResult === 3) ||
    (members[0].battleResult === 3 && members[1].battleResult === 2);

  return (
    <Wrapper>
      <Paper>
        <ResultCharacterBox>
          <BattleContractCharacter />
          <p>{winStatus ? '승리를 축하합니다!' : '이번 대결은 무승부!'}</p>
        </ResultCharacterBox>
        <Frame>
          <BattleContractFrame />
          <ContractBox>
            <TitleBox>
              <p>배틀 계약서</p>
              <BayTreeBox>
                <div>
                  <BayTreeIcon direction={'left'} color={'#F1B417'} />
                </div>
                <p>{battleName}</p>
                <div style={{ paddingBottom: 1 }}>
                  <BayTreeIcon direction={'right'} color={'#F1B417'} />
                </div>
              </BayTreeBox>
            </TitleBox>
            <VersusField>
              <PlayerBox className="right">
                {Number(player1?.battleResult ?? 0) === 2 ? (
                  <BattleContractStatusText status="win" />
                ) : (
                  <BattleContractStatusText status="lose" />
                )}
                <ProfileBox>
                  <BattleProfileImg type="battle" size="big" url={player1?.profileImg} />
                </ProfileBox>
                <TextUnit className="final">최종</TextUnit>
                <TextUnit className="weight">
                  {`${
                    player1?.weightChange > 0 ? '+ ' : player1?.weightChange < 0 ? '- ' : ''
                  } ${Math.abs(player1?.weightChange)}`}
                  <span>kg</span>
                </TextUnit>
                <TextUnit className="goal">
                  목표 -{Math.abs(player1?.weightGoal)}
                  <span>kg</span>
                </TextUnit>
              </PlayerBox>
              <TextUnit className="versus">vs</TextUnit>
              <PlayerBox className="left">
                {Number(player2?.battleResult ?? 0) === 2 ? (
                  <BattleContractStatusText status="win" />
                ) : (
                  <BattleContractStatusText status="lose" />
                )}
                <ProfileBox>
                  <BattleProfileImg type="battle" size="big" url={player2?.profileImg} />
                </ProfileBox>
                <TextUnit className="final">최종</TextUnit>
                <TextUnit className="weight">
                  {`${
                    player2?.weightChange > 0 ? '+ ' : player2?.weightChange < 0 ? '- ' : ''
                  } ${Math.abs(player2?.weightChange)}`}
                  <span>kg</span>
                </TextUnit>
                <TextUnit className="goal">
                  목표 -{Math.abs(player2?.weightGoal)}
                  <span>kg</span>
                </TextUnit>
              </PlayerBox>
            </VersusField>
            <Grid className={classes.infoWrapper}>
              <Grid
                className={classes.infoItem}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid container alignItems={'center'}>
                  <BattleContractPeriodIcon />
                  <p>기간</p>
                </Grid>
                <p className={classes.infoData}>
                  <span className="space">
                    {translateTextDate(battleStartAt, 'M.D')} ~{' '}
                    {translateTextDate(battleEndAt, 'M.D')}{' '}
                  </span>
                  {timeReverseCalculator(battleStartAt, battleEndAt).subtractedDay + 1}일간
                </p>
              </Grid>

              <Grid
                className={classes.infoItem}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid container alignItems={'center'}>
                  <BattleContractCoinIcon />
                  <p>금액</p>
                </Grid>
                <p className={classes.infoData}>
                  <span>
                    {`${battleMoney}`.length > 3
                      ? `${battleMoney}`.slice(0, -3) + ',' + `${battleMoney}`.slice(-3)
                      : `${battleMoney}`}
                  </span>
                  원
                </p>
              </Grid>
              <Grid
                className={classes.infoItem}
                container
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >
                <Grid container alignItems={'center'}>
                  <BattleContractFireIcon />
                  <p>벌칙</p>
                </Grid>
                <p className={classes.infoData2}>{battlePenalty === '' ? '없음' : battlePenalty}</p>
              </Grid>
            </Grid>
          </ContractBox>
        </Frame>
        <div style={{ height: '95px' }} />
      </Paper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 428px;
  height: 100%;
  background-color: #f9cf38;
  overflow-y: scroll;
  padding: 84px 22px 0px;

  z-index: 300;
`;

const Paper = styled.div`
  width: 100%;
  height: 100%;
`;

const Frame = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(428px - 44px);
  margin-top: -7px;
`;

const ResultCharacterBox = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  p {
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translate(-50%, 0);

    text-align: center;
    font-family: Cafe24 Ssurround;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.44px;
  }
`;

const ContractBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 50px 0px 56px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: -7px;

  font-family: Cafe24 Ssurround;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%; /* 24px */
  letter-spacing: -0.437px;
`;

const BayTreeBox = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 12px;
    padding-bottom: 6px;
    font-size: 20px;
  }
`;

const VersusField = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: -10px;

  & .left {
    margin-left: -20px;
  }
  & .right {
    margin-right: -20px;
  }
`;

const PlayerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileBox = styled.div`
  position: relative;
  width: 82px;
  min-width: 82px;
  height: 82px;
  margin-top: 20px;
`;

const TextUnit = styled.p`
  color: black;
  text-align: center;
  font-family: Cafe24 Ssurround;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */

  &.versus {
    font-size: 22px;
    margin-top: 74px;
  }

  &.final {
    font-size: 14px;
    margin-top: 23px;
  }
  &.weight {
    font-size: 22px;
    margin-top: 6px;
  }
  &.goal {
    font-size: 13px;
    color: #cf9708;
    margin-top: 14px;
  }
  span {
    margin-left: 1px;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 82px)',
      gap: 10,
      marginBottom: -10,
    },
    infoItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      '& div': {
        width: 'auto',
        '& svg': {
          width: 24,
          height: 24,
        },
        '& p': {
          fontFamily: 'Cafe24 Ssurround',
          fontWeight: 700,
          lineHeight: '120%',
          letterSpacing: '-0.437px',
          marginLeft: 10,
          fontSize: 14,
        },
      },
    },
    infoData: {
      width: 150,
      fontFamily: 'Cafe24 Ssurround',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: -0.437,
      textAlign: 'right',
      '& .space': {
        marginRight: 8,
      },
    },
    infoData2: {
      width: 150,
      fontFamily: 'Cafe24 Ssurround',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: -0.437,
      textAlign: 'right',
      paddingTop: 4,
    },
  }),
);
