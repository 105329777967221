import { makeStyles, Grid, createStyles } from '@material-ui/core';
import ContainerIntro from '../elements/ContainerIntro';
import ThirdImg from '../../../asset/images/png/img-screen-crop2.png';
import theme from '../../../shared/theme';
import LogoImg from '../../../asset/images/png/logo.png';
import TextImg from '../../../asset/images/png/inout-text.png';

export default function Third() {
  const classes = useStyles();
  const isSmall = theme.isHeightS;

  return (
    <ContainerIntro>
      <Grid className={classes.wrapper} container direction={'column'} alignItems={'center'}>
        <Grid className={classes.logoImg}>
          <img src={LogoImg} alt={'logo'} />
        </Grid>
        <Grid className={classes.textImg}>
          <img src={TextImg} alt={'text'} />
        </Grid>
        <Grid className={classes.footer} container justifyContent={'space-between'}>
          <p>
            <span>070-4157-5005</span>
            <br />
            ketotable@ketotable.kr
          </p>
          <p style={{ paddingTop: 14 }}>© inout Co., Ltd.</p>
        </Grid>
      </Grid>
    </ContainerIntro>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
      padding: '109px 0 70px',
      background: '#9FCEDB',
    },
    logoImg: {
      width: '33%',
      marginBottom: 32,
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    textImg: {
      width: '60%',
      marginBottom: 90,
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    footer: {
      padding: '0 20px 35px',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.4,
        letterSpacing: -0.41,
        color: '#4E8796',
      },
    },
  }),
);
