import SwiperCore, { Navigation, Scrollbar, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { makeStyles, Grid, createStyles } from '@material-ui/core';
import styled from 'styled-components';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import intro1 from '../../../asset/introImg/01.png';
import intro2 from '../../../asset/introImg/02.png';
import intro3 from '../../../asset/introImg/03.png';
import intro4 from '../../../asset/introImg/04.png';
import intro5 from '../../../asset/introImg/05.png';
import intro6 from '../../../asset/introImg/06.png';
import intro7 from '../../../asset/introImg/07.png';
import intro8 from '../../../asset/introImg/08.png';
import intro9 from '../../../asset/introImg/09.png';

import './styles.css';
import ArrowDate from '../../../asset/images/icon/ArrowDate';

export default function App() {
  const classes = useStyles();

  SwiperCore.use([Navigation, Scrollbar, Pagination]);

  const cardList = [intro1, intro2, intro3, intro4, intro5, intro6, intro7, intro8, intro9];
  const [index, setIndex] = useState(0);
  const prevRef = useRef<any>(null);
  const nextRef = useRef<any>(null);
  return (
    <>
      <Grid className={classes.wrapper} container alignItems={'center'}>
        <Swiper
          className={classes.swiper}
          pagination={{
            clickable: true,
            type: 'fraction',
          }}
          navigation={{
            prevEl: prevRef.current, // 이전 버튼
            nextEl: nextRef.current, // 다음 버튼
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.activeIndex = index;
            swiper.navigation.update();
          }}
          onSlideChange={(e) => setIndex(e.activeIndex)}
        >
          {cardList.map((item, index) => (
            <SwiperSlide key={`key${index}`}>
              <button className={classes.leftArrowWrapper} ref={prevRef}>
                <ArrowDate direction={'left'} />
              </button>
              <img src={item} alt={`intro${index}`} />
              <button className={classes.rightArrowWrapper} ref={prevRef}>
                <ArrowDate direction={'right'} />
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    leftArrowWrapper: {
      position: 'absolute',
      left: 25,
      top: '60%',
      zIndex: 999,
    },
    rightArrowWrapper: {
      position: 'absolute',
      right: 25,
      top: '60%',
      zIndex: 999,
    },
    swiper: {
      position: 'relative',
      '& .swiper-pagination': {
        fontSize: 17,
        top: 45,
        fontFamily: 'Campton',
        color: '#D0D2D6',
        LineHeight: 27,
      },
      '& .swiper-pagination-current': {
        fontSize: 17,
        color: '#000000',
        fontWeight: 600,
      },
      '& .swiper-pagination-total': {
        fontSize: 17,
        color: '#D0D2D6',
        fontWeight: 600,
      },
    },
  }),
);
