interface Props {
  direction: 'left' | 'right';
}

export default function ArrowDate({ direction }: Props) {
  const fragment = (() => {
    switch (direction) {
      case 'left':
        return {
          icon: (
            <svg
              width="14"
              height="25"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9 22.3213L2.00047 12.5004L11.9 2.67943"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
      case 'right':
        return {
          icon: (
            <svg
              width="14"
              height="25"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.09998 2.67871L11.9995 12.4996L2.09998 22.3206"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        };
    }
  })();

  return <>{fragment.icon}</>;
}
