const setCookie = (name: string, value: string): void => {
  const date = new Date();

  date.setTime(date.getTime() + 1 * 1000 * 60 * 60 * 24 * 30);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;`;
};

const setCookieFsToken = (name: string, value: string): void => {
  const date = new Date();

  date.setTime(date.getTime() + 1 * 1000 * 60 * 60 * 4);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;`;
};

const getCookie = (name: string) => { 
  const value = `; ${document.cookie}`;
  const parts: string[] = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const part: any = parts.pop();
    return part.split(';').shift();
  }
};

// 쿠키 삭제
const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1999 00:00:10 GMT;path=/;`;
};

export { setCookie, getCookie, deleteCookie, setCookieFsToken };
