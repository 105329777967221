import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './shared';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { deleteCookie, getCookie } from './utils/cookie';
import { ACCESS_TOKEN, BACKURL } from './shared/link';
import { RecoilRoot } from 'recoil';
import LazyLoad from 'react-lazyload';
import { BrowserRouter } from 'react-router-dom';

axios.interceptors.request.use((config) => {
  const token = getCookie(ACCESS_TOKEN);
  config.headers.Authorization = `Bearer ${token}`;

  // Test token
  // const token =
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJJZHgiOjE0ODIwLCJleHAiOjE2MzcxMTUxNDcsImlhdCI6MTYzNDUyMzE0N30.fwRCBRE_UCWWmbpgqgPqoORyQh8ViisPHZm4KArhONo'
  // config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 407) {
      window.location.href = '/intro';
    }
    return Promise.reject(error);
  },
);

axios.defaults.baseURL = BACKURL;

ReactDOM.render(
  <>
    <RecoilRoot>
      <React.Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <LazyLoad>
            <App />
          </LazyLoad>
        </BrowserRouter>
      </React.Suspense>
    </RecoilRoot>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
