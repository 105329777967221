import { Grid, makeStyles, createStyles } from '@material-ui/core';
import ContractBackground from '../../asset/images/png/contract-background.png';
import ContractPaper from '../../asset/images/png/contract-paper.png';
import PeriodIcon from '../../asset/images/png/period-icon.png';
import CoinIcon from '../../asset/images/png/coin-icon.png';
import PenaltyIcon from '../../asset/images/png/penalty-icon.png';
import { timeReverseCalculator, translateTextDate } from '../../module/date';
import classNames from 'classnames';
import { Container } from '../../components/elements/Wrapper/Container';
import BattleProfileImg from './components/BattleProfileImg';
import BayTreeIcon from '../../asset/images/icon/BayTreeIcon';
import FingerPrint from '../../asset/images/icon/FingerPrint';
import ContractOnboarding from './components/ContractOnboarding';
import { deviceOs, nameCountHandler } from '../../module/helper';
import { useBattleContractInfo } from './hooks/BattleHooks';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import BattleBottomButton from './components/BattleBottomButton';
import Contract from './components/Contract';
import ResultContract from './components/ResultContract';

export default function BattleInviteContract() {
  const classes = useStyles();
  const { battleCode } = useParams<{ battleCode: string }>();
  const { loading, battleInfo } = useBattleContractInfo(battleCode);

  const moveToAppStore = () => {
    deviceOs === 'ios'
      ? (window.location.href = 'https://apps.apple.com/app/id1599210729')
      : (window.location.href =
          'https://play.google.com/store/apps/details?id=com.taejinketo.inout_webview');
  };
  return (
    <Container>
      {loading ? (
        <Spinner height={'100vh'} />
      ) : (
        <>
          {battleInfo.battleContractMain.battleStatus === 2 ? (
            <ResultContract battleInfo={battleInfo} />
          ) : (
            <Contract battleInfo={battleInfo} />
          )}
          <BattleBottomButton onClick={moveToAppStore}>
            {battleInfo.battleContractMain.battleStatus === 0
              ? '배틀 시작하기'
              : '나도 배틀 해볼래요!'}
          </BattleBottomButton>
        </>
      )}
    </Container>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      // height: '500%',
      background: '#284164',
    },
    contract: {
      // height: '100%',
      backgroundColor: '#FEE101',
      backgroundImage: `url(${ContractBackground})`,
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      padding: '48px 0 27px',
    },
    bayTreeTitle: {
      marginBottom: 16,
      '& p': {
        fontSize: 15,
        fontWeight: 700,
        lineHeight: 1,
        letterSpacing: -0.44,
        margin: '0 8px',
      },
    },
    title: {
      fontSize: 23,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: -0.44,
    },
    paper: {
      width: '90vw',
      maxWidth: 385.2,
      height: '130vw',
      backgroundColor: '#fff',
      backgroundImage: `url(${ContractPaper})`,
      backgroundSize: '100% 100%',
      borderRadius: 10,
      margin: '35px 0 25px',
      padding: '15px 0 32px',
    },
    mainWrapper: {
      height: 'calc(100% - 114px)',
    },
    paperTitle: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: -0.44,
      textAlign: 'center',
      marginBottom: 40,
    },
    infoWrapper: {
      width: 'calc(100% - 70px)',
    },
    infoItem: {
      width: '100%',
      marginTop: 8,
      '& div': {
        width: 'auto',
        '& img': {
          width: 24,
          height: 24,
          objectFit: 'cover',
        },
        '& p': {
          marginLeft: 8,
          fontSize: 15,
          fontWeight: 500,
          lineHeight: 1,
          letterSpacing: -0.44,
          color: '#999FAB',
        },
      },
    },
    infoData: {
      width: 150,
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: -0.44,
      textAlign: 'right',
      // whiteSpace: 'pre',
      '& span': {
        fontSize: 16,
      },
    },
    playersWrapper: {
      width: '75%',
    },
    player: {
      width: 100,
    },
    name: {
      width: 80,
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.15,
      letterSpacing: -0.58,
      color: '#999FAB',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    profileImgWrapper: {
      position: 'relative',
      width: 40,
      height: 40,
      margin: '10px 0 15px',
    },
    goal: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: -0.5,
      color: '#007AFF',
      marginBottom: 14,
      '& span': {
        fontSize: 20,
      },
    },
    waiting: {
      color: '#999FAB',
    },
    versus: {
      width: 22,
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.52,
      textAlign: 'center',
      marginTop: 25,
    },
    tip: {
      fontSize: 13,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.44,
      color: '#AA9700',
    },
  }),
);
