import { createStyles, Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import BayTreeIcon from '../../../asset/images/icon/BayTreeIcon';
import FingerPrint from '../../../asset/images/icon/FingerPrint';
import CoinIcon from '../../../asset/images/png/coin-icon.png';
import PenaltyIcon from '../../../asset/images/png/penalty-icon.png';
import PeriodIcon from '../../../asset/images/png/period-icon.png';
import BattleContractCoinIcon from '../../../components/elements/contract/BattleContractCoinIcon';
import BattleContractFireIcon from '../../../components/elements/contract/BattleContractFireIcon';
import BattleContractFrame from '../../../components/elements/contract/BattleContractFrame';
import BattleContractPeriodIcon from '../../../components/elements/contract/BattleContractPeriodIcon';
import { BattleContractInfo } from '../../../model/Battle';
import { timeReverseCalculator, translateTextDate } from '../../../module/date';
import BattleProfileImg from './BattleProfileImg';
import ContractOnboarding from './ContractOnboarding';

interface Props {
  battleInfo: BattleContractInfo;
}

export default function Contract({ battleInfo }: Props) {
  const classes = useStyles();
  const {
    battleStartAt,
    battleEndAt,
    battleMoney,
    battleName,
    battlePenalty,
    battleStatus,
    battleInviteCode,
  } = battleInfo.battleContractMain;
  const playerInfo = battleInfo.battleContractContent;
  const isAttended = playerInfo.length === 2;
  const sender = playerInfo.length > 0 ? playerInfo[0] : undefined;
  const recipient = isAttended ? playerInfo[1] : undefined;
  const isContractCompletion = recipient && recipient.signYn === 1;

  return (
    <Grid className={classes.wrapper}>
      <Paper>
        <Frame>
          <BattleContractFrame />
        </Frame>
        <Wrapper>
          <Grid
            className={classes.contract}
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid container direction="column" alignItems="center">
              <p className={classes.subTitle}>배틀 계약서</p>
              <Grid
                className={classes.bayTreeTitle}
                container
                justifyContent={'center'}
                alignItems={'center'}
              >
                <div>
                  <BayTreeIcon direction={'left'} color={'#F1B417'} />
                </div>
                <p>{battleName}</p>
                <div style={{ paddingBottom: 1 }}>
                  <BayTreeIcon direction={'right'} color={'#F1B417'} />
                </div>
              </Grid>
            </Grid>

            <ContentField>
              <UserField className="right">
                <ProfileBox>
                  <BattleProfileImg type="battle" size="big" url={sender?.profileImg} />
                </ProfileBox>
                <UserInfoBox>
                  <p className="goal-text">목표</p>
                  <p className="goal-value">
                    - {Math.abs(sender?.weightGoal ?? 0)}
                    <span>kg</span>
                  </p>
                  <FingerPrint active />
                </UserInfoBox>
              </UserField>
              <VersusUnit>vs</VersusUnit>
              <UserField className="left">
                <ProfileBox>
                  <BattleProfileImg type="battle" size="big" url={recipient?.profileImg} />
                </ProfileBox>
                <UserInfoBox>
                  <p className="goal-text">목표</p>
                  {isContractCompletion ? (
                    <p className="goal-value">
                      - {Math.abs(recipient?.weightGoal ?? 0)}
                      <span>kg</span>
                    </p>
                  ) : (
                    <p className="wait">입력 전</p>
                  )}
                  <FingerPrint active={isContractCompletion} />
                </UserInfoBox>
              </UserField>
            </ContentField>

            <Grid className={classes.infoWrapper}>
              <Grid
                className={classes.infoItem}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid container alignItems={'center'}>
                  <BattleContractPeriodIcon />
                  <p>기간</p>
                </Grid>
                <p className={classes.infoData}>
                  <span className="space">
                    {translateTextDate(battleStartAt, 'M.D')} ~{' '}
                    {translateTextDate(battleEndAt, 'M.D')}{' '}
                  </span>
                  {timeReverseCalculator(battleStartAt, battleEndAt).subtractedDay + 1}일간
                </p>
              </Grid>

              <Grid
                className={classes.infoItem}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid container alignItems={'center'}>
                  <BattleContractCoinIcon />
                  <p>금액</p>
                </Grid>
                <p className={classes.infoData}>
                  <span>
                    {`${battleMoney}`.length > 3
                      ? `${battleMoney}`.slice(0, -3) + ',' + `${battleMoney}`.slice(-3)
                      : `${battleMoney}`}
                  </span>
                  원
                </p>
              </Grid>

              <Grid
                className={classes.infoItem}
                container
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                style={{ height: 42 }}
              >
                <Grid container alignItems={'center'}>
                  <BattleContractFireIcon />
                  <p>벌칙</p>
                </Grid>
                <p className={classes.infoData2}>{battlePenalty === '' ? '없음' : battlePenalty}</p>
              </Grid>
            </Grid>
          </Grid>
        </Wrapper>
      </Paper>
      <ContractOnboarding isContracting={battleStatus === 0} battleCode={battleInviteCode} />
    </Grid>
  );
}

const Paper = styled.div`
  position: relative;
  padding: 20px;
  background-color: #f9cf38;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 40px);
`;

const Frame = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
`;

const ContentField = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  & .left {
    margin-left: -20px;
  }
  & .right {
    margin-right: -20px;
  }
`;

const UserField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
`;

const ProfileBox = styled.div`
  position: relative;
  width: 82px;
  min-width: 82px;
  height: 82px;
`;

const UserInfoBox = styled.div`
  text-align: center;

  & .goal-text {
    text-align: center;
    font-family: Cafe24 Ssurround;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 6px;
  }

  & .goal-value {
    text-align: center;
    font-family: Cafe24 Ssurround;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  & .wait {
    text-align: center;
    font-family: Cafe24 Ssurround;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  span {
    margin-left: 1px;
  }

  svg {
    margin-top: 14px;
  }
`;

const VersusUnit = styled.p`
  padding-top: 26px;
  text-align: center;
  font-family: Cafe24 Ssurround;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      background: '#284164',
    },
    contract: {
      padding: '65px 0 20px',
    },
    bayTreeTitle: {
      '& p': {
        fontFamily: 'Cafe24 Ssurround',
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '120%',
        letterSpacing: -0.44,
        margin: '0 12px',
        paddingBottom: 4,
      },
    },
    subTitle: {
      fontFamily: 'Cafe24 Ssurround',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: -0.44,
      marginBottom: 9,
    },
    infoWrapper: {
      width: 'calc(100% - 82px)',
      margin: '0 0 27px -3px',
    },
    infoItem: {
      width: '100%',
      marginTop: 8,
      '& div': {
        width: 'auto',
        '& svg': {
          width: 24,
          height: 24,
        },
        '& p': {
          fontFamily: 'Cafe24 Ssurround',
          fontWeight: 700,
          lineHeight: '120%',
          letterSpacing: '-0.437px',
          marginLeft: 10,
          fontSize: 14,
        },
      },
    },
    infoData: {
      width: 150,
      fontFamily: 'Cafe24 Ssurround',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: -0.437,
      textAlign: 'right',
      '& .space': {
        marginRight: 8,
      },
    },
    infoData2: {
      width: 150,
      fontFamily: 'Cafe24 Ssurround',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: -0.437,
      textAlign: 'right',
      paddingTop: 4,
    },
  }),
);
