import React, { useEffect } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Route, useHistory } from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import theme from './theme';
import Intro from '../pages/intro/Intro';
import InoutPCMode from '../components/InoutPCMode';
import BattleInviteContract from '../pages/battleContract/BattleInviteContract';
import StoreRedirect from '../pages/storeRedirect/StoreRedirect';

const useStyle = makeStyles(() =>
  createStyles({
    inoutMode: {
      [`@media ${theme.devices.inoutPC}`]: {
        display: 'none',
        width: '100vw',
        maxWidth: 428,
        height: '100vh',
        margin: 'auto',
        background: '#fff',
      },
    },
    pcMode: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'none',
      [`@media ${theme.devices.inoutPC}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }),
);

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

export const App: React.FC = () => {
  const classes = useStyle();
  const history = useHistory();
  useEffect(() => {
    // history.replace('/intro');
    // return;
  }, []);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Grid className={classes.pcMode}>
        <InoutPCMode />
      </Grid>
      <Grid className={classes.inoutMode}>
        {/* intro */}
        <Route path={'/'} exact component={Intro} />
        <Route path={'/intro'} exact component={Intro} />
        <Route path={'/introUser'} exact component={Intro} />
        <Route path={'/battle/:battleCode'} exact component={BattleInviteContract} />
        <Route path={'/store'} exact component={StoreRedirect} />
      </Grid>
    </StylesProvider>
  );
};

export default App;
