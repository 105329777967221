import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { PropsWithChildren } from 'react';

interface Props {
  onClick(): void;
}

export default function BattleBottomButton({ onClick, children }: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        className={classes.button}
        onClick={onClick}
      >
        <span>{children}</span>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: 428,
      width: '100%',
      position: 'fixed',
      bottom: 0,
      zIndex: 2101,
      padding: 12,
      background: '#fff',
    },
    button: {
      height: 54,
      background: 'black',
      borderRadius: 120,
      '& span': {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: '-0.62px',
        textAlign: 'center',
        color: '#fff',
      },
    },
  }),
);
