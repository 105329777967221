import { makeStyles, Grid, createStyles } from '@material-ui/core';
import ContainerIntro from '../elements/ContainerIntro';
import IntroFirstPng from '../../../asset/images/png/img-main@3x.png';
import logo from '../../../asset/images/png/img-logo-login@3x.png';
import IntroFirstPngCrop from '../../../asset/images/png/img-main-crop.png';
import classNames from 'classnames';
import ArrowScroll from '../../../asset/images/icon/ArrowScroll';
import theme from '../../../shared/theme';
import google from '../../../asset/images/png/btn-google@3x.png';
import IntroGif from '../../../asset/images/gif/login-gif-01.gif';
import AosStoreLogo from '../../../asset/images/png/logo-aos@3x.png';
import IosStoreLogo from '../../../asset/images/png/logo-ios@3x.png';
import FirstImg from '../../../asset/images/png/main-img.png';
import FirstTitleText from '../../../asset/images/png/hashtag-title.png';
import FirstContentText from '../../../asset/images/png/first-intro-title.png';
import { APPSTOREURL, GOOGLESTOREURL } from '../../../shared/link';

export default function First() {
  const classes = useStyles();

  return (
    <ContainerIntro first>
      <Grid className={classes.wrapper} container direction={'column'} alignItems={'center'}>
        <Grid className={classes.hashtagImg}>
          <img src={FirstTitleText} alt={'hashtag'} />
        </Grid>
        <Grid className={classes.titleImg}>
          <img src={FirstContentText} alt={'title'} />
        </Grid>

        <Grid className={classes.mainImg}>
          <img src={FirstImg} alt={'main img'} />
        </Grid>
      </Grid>
    </ContainerIntro>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      paddingTop: 100,
    },
    hashtagImg: {
      width: '40%',
      marginBottom: 24,
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    titleImg: {
      position: 'relative',
      zIndex: 100,
      width: '57%',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    mainImg: {
      width: '100%',
      height: '100vw',
      maxHeight: 428,
      marginTop: '-5%',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
  }),
);
