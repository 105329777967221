import { makeStyles, Grid, createStyles } from '@material-ui/core';
import Google from '../../../asset/google_icon.png';
import Apple from '../../../asset/apple_icon.png';
import AppIcon from '../../../asset/appstore.png';
import { APPSTOREURL, GOOGLESTOREURL } from '../../../shared/link';

export default function IntroGNB() {
  const classes = useStyles();

  const googleStore = () => {
    window.open(GOOGLESTOREURL, '_blank');
  };

  const appStore = () => {
    window.open(APPSTOREURL, '_blank');
  };

  return (
    <Grid container alignItems={'center'} justify={'space-between'} className={classes.container}>
      <Grid className={classes.AppIconContainer}>
        <img src={AppIcon} alt="appIcon" />
      </Grid>
      <Grid className={classes.btnContainer}>
        <img src={Google} alt="google" onClick={googleStore} />
        <img
          src={Apple}
          alt="apple"
          style={{ marginLeft: 15, marginRight: 20 }}
          onClick={appStore}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: 75,
      maxWidth: 428,
      width: '100vw',
      background: '#ffffff',
      zIndex: 1000,
    },
    partition: {
      width: '100%',
      height: 22,
      background: '#555555',
    },

    btnContainer: {
      width: 'auto',
      '& img': {
        width: '30px',
        objectFit: 'cover',
      },
    },

    AppIconContainer: {
      width: '75px',
      height: '75px',
      padding: 15,
      '& img': {
        width: '100%',
        objectFit: 'cover',
      },
    },
  }),
);
