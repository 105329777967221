import { makeStyles, Grid, createStyles } from '@material-ui/core';
import Google from '../../../asset/images/png/google-button.png';
import Apple from '../../../asset/images/png/apple-button.png';
import { APPSTOREURL, GOOGLESTOREURL } from '../../../shared/link';

export default function IntroBottomButton() {
  const classes = useStyles();

  const googleStore = () => {
    window.open(GOOGLESTOREURL, '_blank');
  };

  const appStore = () => {
    window.open(APPSTOREURL, '_blank');
  };

  return (
    <Grid container alignItems={'center'} justify={'space-between'} className={classes.container}>
      <Grid className={classes.buttonWrapper} container alignItems={'center'} onClick={googleStore}>
        <img src={Google} alt={'google'} />
      </Grid>
      <Grid className={classes.partition} />
      <Grid className={classes.buttonWrapper} container alignItems={'center'} onClick={appStore}>
        <img src={Apple} alt={'google'} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      height: 70,
      maxWidth: 428,
      width: '100vw',
      background: '#000',
      zIndex: 1000,
    },
    partition: {
      width: 1,
      height: 22,
      background: '#555555',
    },
    buttonWrapper: {
      width: 'calc(50% - 1px)',
      height: '100%',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
  }),
);
