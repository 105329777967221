export const ACCESS_TOKEN = 'is_login';
export const FS_TOKEN = 'fsKey';
export const KAKAOURL = 'https://devkeserv.com/auth/kakao';
// export const KAKAOURL = 'https://keserv.com/auth/kakao';
export const NAVERURL = 'https://devkeserv.com/auth/naver';
// export const NAVERURL = 'https://keserv.com/auth/naver';

export const BACKURL = 'https://keserv.com';
// export const BACKURL = 'https://devkeserv.com';
export const BATTLEURL = 'https://inoutchat.com';
// export const BATTLEURL = 'https://deoutchat.com';

export const GOOGLESTOREURL =
  'https://play.google.com/store/apps/details?id=com.taejinketo.inout_webview&utm_source=inout&utm_medium=web_intro&utm_campaign=app_down_link&utm_content=inout_playstore';

export const APPSTOREURL =
  'https://apps.apple.com/us/app/%EC%9D%B8%EC%95%84%EC%9B%83-%ED%82%A4%ED%86%A0-%EC%8B%9D%EB%8B%A8-%EC%9D%BC%EA%B8%B0/id1599210729?utm_source=inout&utm_medium=web_intro&utm_campaign=app_down_link&utm_content=inout_appstore';
