import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

export const Container: React.FC = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.wrapper}>{children}</div>;
};
const useStyle = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      maxWidth: 428,
      width: '100%',
      minHeight: '100%',
      margin: '0 auto',
      cursor: 'default',
      background: '#fff',
    },
  }),
);
