const BattleContractCharacter = () => {
  return (
    <svg
      width="317"
      height="113"
      viewBox="0 0 317 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_2904_48763" maskUnits="userSpaceOnUse" x="99" y="53" width="121" height="60">
        <rect x="99" y="53" width="121" height="60" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2904_48763)">
        <path
          d="M128.985 159.196C125.253 137.956 124.54 103.468 124.893 86.2459L183.971 69.8651C196.5 86.2459 214.387 107.871 226.5 126C242.328 149.69 217.342 187.014 203.107 190.591C188.873 194.167 188.933 194.376 177.376 197.055C153.71 202.541 133.649 185.745 128.985 159.196Z"
          fill="#FFD99F"
        />
        <path
          d="M110.707 93.1527C108.929 92.1995 104.172 90.8349 101.27 93.8758C98.991 96.2633 99.5848 101.456 102.604 106.542C105.623 111.628 114.073 122.58 122.695 123.289C129.593 123.856 133.174 111.497 134.102 105.246C126.655 101.232 112.014 93.8542 110.707 93.1527Z"
          fill="#FFD99F"
        />
        <path
          d="M203.343 62.5451C204.806 60.6152 208.189 57.5652 212.974 59.9087C216.336 61.5558 217.922 66.5927 217.368 72.5141C216.815 78.4354 212.857 91.0135 210.223 104.831C208.902 111.757 191.034 87.8505 187.384 82.1795C192.549 75.7528 202.052 64.2485 203.343 62.5451Z"
          fill="#FFD99F"
        />
        <circle cx="157" cy="87" r="32" fill="#FFD89F" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M154.022 92.3831C153.766 91.4553 154.313 90.506 155.245 90.2628L161.971 88.5065C162.903 88.2633 163.866 88.8183 164.122 89.7461C164.378 90.6739 163.83 91.6232 162.899 91.8664L156.172 93.6227C155.241 93.8659 154.278 93.3109 154.022 92.3831Z"
          fill="black"
        />
        <ellipse
          cx="3.20194"
          cy="3.20215"
          rx="3.20194"
          ry="3.20215"
          transform="matrix(0.969616 -0.242151 0.276537 0.960304 142.528 84.6816)"
          fill="black"
        />
        <ellipse
          cx="3.20194"
          cy="3.20215"
          rx="3.20194"
          ry="3.20215"
          transform="matrix(0.969616 -0.242151 0.276537 0.960304 163.481 79.0684)"
          fill="black"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.4782 37.0294C37.5803 38.4219 37.9812 40.2786 39.3737 41.1765L69.3742 60.5214C70.7667 61.4193 72.6234 61.0184 73.5212 59.6259C74.4191 58.2334 74.0182 56.3767 72.6257 55.4788L42.6252 36.1339C41.2328 35.236 39.3761 35.637 38.4782 37.0294Z"
        fill="#FEFF92"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M285.871 83.6766C286.103 85.3172 284.961 86.8353 283.321 87.0674L251.084 91.6296C249.444 91.8617 247.926 90.72 247.694 89.0795C247.462 87.439 248.603 85.9209 250.244 85.6887L282.48 81.1266C284.121 80.8945 285.639 82.0361 285.871 83.6766Z"
        fill="#FEFF92"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M284.596 38.171C285.002 36.4509 283.936 34.7277 282.216 34.3221C274.124 32.414 260.828 36.8713 254.429 49.7764C252.664 53.3366 250.473 56.2106 248.314 58.2344C246.091 60.3178 244.151 61.2718 242.93 61.4648C241.184 61.7407 239.993 63.3795 240.269 65.1251C240.545 66.8707 242.183 68.0622 243.929 67.7863C246.924 67.3129 249.995 65.4298 252.69 62.9043C255.449 60.3192 258.084 56.8126 260.163 52.6196C265.297 42.2664 275.673 39.3547 280.747 40.5512C282.467 40.9568 284.191 39.8912 284.596 38.171Z"
        fill="#938CFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.8979 86.4231C36.6164 85.206 36.5643 83.1805 37.7814 81.8991C42.6358 76.7882 53.4784 74.267 62.5659 81.5397C64.8466 83.365 67.1286 84.4926 69.0581 85.0304C71.0696 85.5912 72.3596 85.4271 72.9134 85.1852C74.533 84.4778 76.4194 85.2173 77.1268 86.8368C77.8342 88.4564 77.0947 90.3428 75.4752 91.0502C73.0543 92.1076 70.109 91.9674 67.3395 91.1954C64.488 90.4005 61.4434 88.8386 58.5669 86.5365C52.1833 81.4277 45.0832 83.5046 42.4218 86.3066C41.2047 87.5881 39.1793 87.6402 37.8979 86.4231Z"
        fill="#FA5064"
      />
      <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 61 0)" fill="#FEFF92" />
      <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 10 49)" fill="#938CFF" />
      <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 270 0)" fill="#FA5064" />
      <circle cx="5" cy="5" r="5" transform="matrix(-1 0 0 1 317 52)" fill="#FEFF92" />
    </svg>
  );
};

export default BattleContractCharacter;
