const BattleContractFrame = () => {
  return (
    <svg viewBox="0 0 339 562" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1737 14.9724C12.1707 9.92755 6.09148 3.64376 3.00233 6.38336C-1.32248 10.2188 9.53348 17.9418 13.0641 18.9855C16.7711 20.0813 18.3798 19.2965 18.3798 13.531C18.3798 7.76549 17.178 2 24.9894 2C32.8009 2 307.403 2 314.012 2C320.622 2 320.622 4.16206 320.622 8.48618C320.622 12.8103 320.622 12.0896 320.622 15.693C320.622 19.2965 324.227 21.4585 329.034 17.1344C333.841 12.8103 337.615 7.05192 335.043 4.88275C332.924 3.09584 327.833 6.32412 324.828 14.9724"
        stroke="#F1B417"
        stroke-width="3.6"
        stroke-linecap="round"
      />
      <path
        d="M16.7706 25.0413C16.7706 25.8022 16.2192 27.3241 14.0135 27.3241C11.2565 27.3241 9.60227 27.3241 7.94804 27.3241C6.29381 27.3241 4 27.228 4 32.5547C4 37.8815 4 523.136 4 526.941C4 530.746 3.53618 532.951 7.39605 532.951C11.2559 532.951 11.8073 532.951 12.9101 532.951C14.0129 532.951 15.1158 532.951 15.6672 534.473"
        stroke="#F1B417"
        stroke-width="3.6"
        stroke-linecap="round"
      />
      <path
        d="M322.859 24.9445C322.859 25.7054 323.411 27.2273 325.616 27.2273C328.373 27.2273 330.028 27.2273 331.682 27.2273C333.336 27.2273 335.542 27.2273 335.542 32.5541C335.542 37.8808 335.542 523.636 335.542 527.441C335.542 531.246 336.093 533.529 332.233 533.529C328.373 533.529 327.822 533.529 326.719 533.529C325.616 533.529 324.513 533.529 323.962 535.051"
        stroke="#F1B417"
        stroke-width="3.6"
        stroke-linecap="round"
      />
      <path
        d="M14.1236 547.634C12.1257 551.772 6.70966 559.438 3.53224 556.627C-0.0597674 553.45 6.2783 547.634 11.3374 543.936C15.1828 541.125 18.53 540.889 18.53 546.126C18.53 551.968 18.53 551.968 18.53 554.158C18.53 556.349 18.53 560 24.5237 560C30.5175 560 311.625 560 316.42 560C321.215 560 320.616 557.079 320.616 554.158C320.616 551.238 320.616 548.317 320.616 546.126C320.616 543.936 321.215 542.475 324.811 543.206C328.408 543.936 340.338 551.569 336.102 556.065C333.149 559.2 327.209 552.698 324.811 547.587"
        stroke="#F1B417"
        stroke-width="3.6"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default BattleContractFrame;
