import { Grid, makeStyles, createStyles } from '@material-ui/core';
import classNames from 'classnames';
import DefaultProfile from '../../../asset/images/png/default-profile-battle.png';
import DefaultProfileNone from '../../../asset/images/png/default-profile-none.png';
import MyCheck from '../../../asset/images/png/me-check-badge.png';

interface Props {
  url: string | null | undefined;
  isMe?: boolean;
  none?: boolean;
  size?: 'large' | 'small' | 'medium' | 'big';
  type?: 'battle' | 'profile';
}

export default function BattleProfileImg({
  url,
  isMe,
  none,
  size = 'large',
  type = 'profile',
}: Props) {
  const classes = useStyles();
  const urlHandler = (() => {
    if (none || url === undefined) {
      return DefaultProfileNone;
    } else if (url === null || url === '0') {
      return DefaultProfile;
    } else {
      return url;
    }
  })();

  return (
    <Grid
      className={classNames([
        classes.wrapper,
        size === 'medium' && classes.mediumWrapper,
        size === 'small' && classes.smallWrapper,
        size === 'big' && classes.bigWrapper,
      ])}
      container
      justifyContent={'center'}
      alignItems={'center'}
    >
      {(url === null || url === '0' || url === undefined) && type === 'battle' ? (
        <ProfileBattle />
      ) : (
        <img src={urlHandler} alt={'profile'} />
      )}
      {isMe && (
        <Grid className={classes.myCheckBadge}>
          <img src={MyCheck} alt={'my check'} />
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      width: 40,
      height: 40,
      borderRadius: '50%',
      background: '#E6E7EA',
      '& img': {
        width: 38.5,
        height: 38.5,
        borderRadius: '50%',
        objectFit: 'cover',
      },
    },
    mediumWrapper: {
      width: 32,
      height: 32,
      '& img': {
        width: 30.5,
        height: 30.5,
      },
    },
    smallWrapper: {
      width: 25,
      height: 25,
      '& img': {
        width: 23.5,
        height: 23.5,
      },
      '& svg': {
        width: 23.5,
        height: 23.5,
      },
    },
    bigWrapper: {
      width: 82,
      height: 82,
      '& img': {
        width: 82,
        height: 82,
      },
    },
    myCheckBadge: {
      position: 'absolute',
      bottom: 2,
      right: -4,
      width: 14,
      height: 14,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  }),
);

const ProfileBattle = () => (
  <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="41" cy="41" r="41" fill="#E6E7EA" />
    <path
      d="M33.0671 48.1309C33.9167 52.4229 37.3733 54.7042 40.9938 54.7042C45.006 54.7042 48.1636 52.436 48.9388 48.1386"
      stroke="black"
      stroke-width="3.94777"
      stroke-linecap="round"
    />
    <circle cx="27.4968" cy="37.4795" r="3.78804" fill="black" />
    <circle cx="54.5012" cy="37.4795" r="3.78804" fill="black" />
  </svg>
);
