import { makeStyles, Grid, createStyles, Theme } from "@material-ui/core";
import { PropsWithChildren } from "react";

interface Props {
  large?: boolean;
  last?: boolean;
  first?: boolean;
}

export default function ContainerIntro({children, large, last, first}: PropsWithChildren<Props>) {
  const classes = useStyles({large, last, first});

  return <div className={classes.container}>{children}</div>
}

const useStyles = makeStyles<Theme, Props>((theme) => 
  createStyles({
    container: {
      position: 'relative',
      maxWidth: 428,
      width: '100vw',
      cursor: 'default',
      background: '#fff',
    }
  })
)