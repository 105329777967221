import { makeStyles, Grid, createStyles } from '@material-ui/core';
import Eighth from './components/Eighth';
import Fifth from './components/Fifth';
import First from './components/First';
import FirstVideo from './components/FirstVideo';
import Fourth from './components/Fourth';
import Second from './components/Second';
import Seventh from './components/Seventh';
import Sixth from './components/Sixth';
import Third from './components/Third';
import IntroGNB from './elements/IntroGNB';
import { useEffect, useRef, useState } from 'react';
import theme from '../../shared/theme';
import { useHistory } from 'react-router';
import IntroBottomButton from './elements/IntroBottomButton';
// import "swiper/css/bundle";
// import "../intro/components/styles.css";

export default function Intro() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [buttonOn, setButtonOn] = useState(false);
  const introCheck = localStorage.getItem('IntroCheck');
  const history = useHistory();

  // useEffect(() => {
  //   if (path === 'intro' && introCheck === '1') {
  //     history.push('/login')
  //   }
  // }, [])

  const buttonScrollHandler = () => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop < 500) {
      setButtonOn(false);
    } else {
      setButtonOn(true);
    }
  };

  useEffect(() => {
    // scroll event listener 등록
    window.addEventListener('scroll', buttonScrollHandler);
    return () => {
      // scroll event listener 해제
      window.removeEventListener('scroll', buttonScrollHandler);
    };
  }, []);

  return (
    <>
      <IntroGNB />
      <First />
      <FirstVideo />
      <Second />
      <Third />
      <IntroBottomButton />
      {/* <Fourth/>
        <Fifth/>
        <Sixth/>
        <Seventh/>
        <Eighth/> */}
    </>
  );
}

const useStyles = makeStyles(() => createStyles({}));
