// const isHeightS = window.screen.availHeight < 610;
// const isHeightS = window.screen.height < 650;
const isHeightS = matchMedia('only screen and (max-height: 627px)').matches;
// mini기준 627

const deviceSizes = {
  mobileMax: "768px",
  pcMin: "1024px",

  mobileHeightS: "627px",


  inoutPC: "429px"
}

const devices = {
  mobileMax: `only screen and (max-width: ${deviceSizes.mobileMax})`,
  pcMin: `only screen and (min-width: ${deviceSizes.pcMin})`,
  mobileHeightS: `only screen and (max-height: ${deviceSizes.mobileHeightS})`,

  inoutPC: `only screen and (min-width: ${deviceSizes.inoutPC})`,
}

const theme = {
  deviceSizes,
  devices,
  isHeightS
}

export default theme;