interface DefaultProps {
  color?: string;
  size?: 'default' | 'large';
}

interface Props extends DefaultProps {
  direction: 'left' | 'right';
}

export default function BayTreeIcon({ direction, color, size }: Props) {
  const component = {
    left: <LeftBayTree color={color} size={size} />,
    right: <RightBayTree color={color} size={size} />,
  };
  return component[direction];
}

const LeftBayTree = ({ color, size = 'default' }: DefaultProps) => {
  const component = {
    default: <DefaultLeftBayTree color={color} />,
    large: <LargeLeftBayTree color={color} />,
  };
  return component[size];
};

const RightBayTree = ({ color, size = 'default' }: DefaultProps) => {
  const component = {
    default: <DefaultRightBayTree color={color} />,
    large: <LargeRightBayTree color={color} />,
  };
  return component[size];
};

const DefaultLeftBayTree = ({ color = '#FFB800' }: Pick<DefaultProps, 'color'>) => {
  return (
    <svg width="22" height="41" viewBox="0 0 22 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0784 32.0001C20.0902 33.128 20.9477 37.0653 21 38.893C17.4863 40.8981 14.2026 39.7284 13 38.893C14.1294 37.5144 17.4444 37.3786 18.9608 37.4831C16.8275 35.8538 16.817 33.1489 17.0784 32.0001Z"
        fill={color}
      />
      <path
        d="M13.2047 27.5845C15.6807 29.8475 14.9791 33.9961 14.3187 35.7876C10.1382 36.3657 7.4153 33.9566 6.57642 32.6798C8.20571 31.7826 11.4668 32.9388 12.8937 33.6291C11.4628 31.2215 12.5048 28.5962 13.2047 27.5845Z"
        fill={color}
      />
      <path
        d="M10.9551 22.8393C12.5073 25.8129 10.4285 29.4711 9.19512 30.9284C5.06906 30.0413 3.3348 26.8459 2.98343 25.3591C4.82135 25.0736 7.48998 27.2758 8.59456 28.4126C8.07381 25.6608 9.95124 23.5505 10.9551 22.8393Z"
        fill={color}
      />
      <path
        d="M9.86386 17.6269C10.5001 20.9204 7.46118 23.8305 5.86222 24.8739C2.16271 22.8429 1.41561 19.2848 1.5045 17.7596C3.34727 18.0121 5.27391 20.886 6.00689 22.2914C6.29557 19.5057 8.69849 18.021 9.86386 17.6269Z"
        fill={color}
      />
      <path
        d="M11.4969 13.2209C10.7144 16.4827 6.74425 17.876 4.85697 18.1649C2.32821 14.786 3.11909 11.2374 3.83063 9.88548C5.40413 10.8772 5.97012 14.2906 6.05643 15.8733C7.47109 13.4562 10.2728 13.0979 11.4969 13.2209Z"
        fill={color}
      />
      <path
        d="M14.2597 10.2997C11.8996 12.6834 7.78233 11.8166 6.01869 11.0852C5.60795 6.88494 8.12391 4.26043 9.43324 3.47321C10.2646 5.13703 8.97907 8.34932 8.23237 9.74749C10.6952 8.41391 13.2768 9.56 14.2597 10.2997Z"
        fill={color}
      />
      <path
        d="M19.2944 8.59638C16.3874 10.2701 12.6465 8.34417 11.1394 7.17198C11.8554 3.01281 14.9765 1.14808 16.4475 0.735609C16.8087 2.56017 14.7185 5.31747 13.6283 6.46805C16.3563 5.83411 18.5423 7.6228 19.2944 8.59638Z"
        fill={color}
      />
    </svg>
  );
};

const LargeLeftBayTree = ({ color = '#F1B417' }: Pick<DefaultProps, 'color'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="40" viewBox="0 0 22 40" fill="none">
      <path
        d="M17.0784 32C20.0902 33.1279 20.9477 37.0652 21 38.8928C17.4863 40.898 14.2026 39.7283 13 38.8928C14.1294 37.5143 17.4444 37.3785 18.9608 37.4829C16.8275 35.8537 16.817 33.1488 17.0784 32Z"
        fill={color}
      />
      <path
        d="M13.2047 27.584C15.6807 29.847 14.9791 33.9956 14.3187 35.7871C10.1382 36.3652 7.4153 33.9561 6.57642 32.6793C8.20571 31.7821 11.4668 32.9383 12.8937 33.6286C11.4628 31.221 12.5048 28.5957 13.2047 27.584Z"
        fill={color}
      />
      <path
        d="M10.9551 22.8403C12.5073 25.8139 10.4285 29.472 9.19512 30.9294C5.06906 30.0423 3.3348 26.8469 2.98343 25.3601C4.82135 25.0745 7.48998 27.2768 8.59456 28.4136C8.07381 25.6618 9.95124 23.5514 10.9551 22.8403Z"
        fill={color}
      />
      <path
        d="M9.86386 17.6259C10.5001 20.9194 7.46118 23.8295 5.86222 24.8729C2.16271 22.8419 1.41561 19.2838 1.5045 17.7586C3.34727 18.0111 5.27391 20.885 6.00689 22.2904C6.29557 19.5047 8.69849 18.02 9.86386 17.6259Z"
        fill={color}
      />
      <path
        d="M11.4969 13.2208C10.7144 16.4826 6.74425 17.8759 4.85697 18.1648C2.32821 14.7859 3.11909 11.2373 3.83063 9.88536C5.40413 10.8771 5.97012 14.2905 6.05643 15.8732C7.47109 13.4561 10.2728 13.0978 11.4969 13.2208Z"
        fill={color}
      />
      <path
        d="M14.2592 10.2999C11.8991 12.6835 7.78184 11.8167 6.01821 11.0854C5.60746 6.88506 8.12342 4.26055 9.43275 3.47334C10.2641 5.13715 8.97859 8.34944 8.23188 9.74761C10.6947 8.41404 13.2763 9.56012 14.2592 10.2999Z"
        fill={color}
      />
      <path
        d="M19.2939 8.59687C16.3869 10.2706 12.646 8.34466 11.139 7.17247C11.8549 3.0133 14.976 1.14857 16.447 0.736098C16.8082 2.56066 14.718 5.31796 13.6278 6.46854C16.3558 5.8346 18.5419 7.62328 19.2939 8.59687Z"
        fill={color}
      />
    </svg>
  );
};

const DefaultRightBayTree = ({ color = '#FFB800' }: Pick<DefaultProps, 'color'>) => {
  return (
    <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.92157 32.0001C1.9098 33.128 1.05229 37.0653 1 38.893C4.51373 40.8981 7.79739 39.7284 9 38.893C7.87059 37.5144 4.55556 37.3786 3.03922 37.4831C5.17255 35.8538 5.18301 33.1489 4.92157 32.0001Z"
        fill={color}
      />
      <path
        d="M8.79526 27.5845C6.31925 29.8475 7.02092 33.9961 7.68125 35.7876C11.8618 36.3657 14.5847 33.9566 15.4236 32.6798C13.7943 31.7826 10.5332 32.9388 9.10635 33.6291C10.5372 31.2215 9.49515 28.5962 8.79526 27.5845Z"
        fill={color}
      />
      <path
        d="M11.0449 22.8393C9.49268 25.8129 11.5715 29.4711 12.8049 30.9284C16.9309 30.0413 18.6652 26.8459 19.0166 25.3591C17.1787 25.0736 14.51 27.2758 13.4054 28.4126C13.9262 25.6608 12.0488 23.5505 11.0449 22.8393Z"
        fill={color}
      />
      <path
        d="M12.1361 17.6269C11.4999 20.9204 14.5388 23.8305 16.1378 24.8739C19.8373 22.8429 20.5844 19.2848 20.4955 17.7596C18.6527 18.0121 16.7261 20.886 15.9931 22.2914C15.7044 19.5057 13.3015 18.021 12.1361 17.6269Z"
        fill={color}
      />
      <path
        d="M10.5031 13.2209C11.2856 16.4827 15.2558 17.876 17.143 18.1649C19.6718 14.786 18.8809 11.2374 18.1694 9.88548C16.5959 10.8772 16.0299 14.2906 15.9436 15.8733C14.5289 13.4562 11.7272 13.0979 10.5031 13.2209Z"
        fill={color}
      />
      <path
        d="M7.74027 10.2997C10.1004 12.6834 14.2177 11.8166 15.9813 11.0852C16.3921 6.88494 13.8761 4.26043 12.5668 3.47321C11.7354 5.13703 13.0209 8.34932 13.7676 9.74749C11.3048 8.41391 8.72322 9.56 7.74027 10.2997Z"
        fill={color}
      />
      <path
        d="M2.70564 8.59638C5.61262 10.2701 9.3535 8.34417 10.8606 7.17198C10.1446 3.01281 7.02354 1.14808 5.55252 0.735609C5.1913 2.56017 7.28147 5.31747 8.3717 6.46805C5.64371 5.83411 3.45766 7.6228 2.70564 8.59638Z"
        fill={color}
      />
    </svg>
  );
};

const LargeRightBayTree = ({ color = '#F1B417' }: Pick<DefaultProps, 'color'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="41" viewBox="0 0 22 41" fill="none">
      <path
        d="M5.04042 32.5C1.95594 33.6279 1.07772 37.5652 1.02417 39.3928C4.62273 41.398 7.98566 40.2283 9.21731 39.3928C8.06063 38.0143 4.66557 37.8785 3.11262 37.9829C5.29746 36.3537 5.30817 33.6488 5.04042 32.5Z"
        fill={color}
      />
      <path
        d="M9.00749 28.084C6.47171 30.347 7.19031 34.4956 7.86659 36.2871C12.1481 36.8652 14.9367 34.4561 15.7958 33.1793C14.1272 32.2821 10.7874 33.4383 9.32609 34.1286C10.7915 31.721 9.72428 29.0957 9.00749 28.084Z"
        fill={color}
      />
      <path
        d="M11.3116 23.3403C9.72183 26.3139 11.8508 29.972 13.114 31.4294C17.3397 30.5423 19.1158 27.3469 19.4757 25.8601C17.5934 25.5746 14.8603 27.7768 13.7291 28.9136C14.2624 26.1618 12.3396 24.0514 11.3116 23.3403Z"
        fill={color}
      />
      <path
        d="M12.4292 18.1259C11.7776 21.4194 14.8899 24.3295 16.5274 25.3729C20.3162 23.3419 21.0814 19.7838 20.9903 18.2586C19.1031 18.5111 17.1299 21.385 16.3793 22.7904C16.0836 20.0047 13.6227 18.52 12.4292 18.1259Z"
        fill={color}
      />
      <path
        d="M10.7567 13.7208C11.5581 16.9826 15.6241 18.3759 17.5569 18.6648C20.1467 15.2859 19.3368 11.7373 18.6081 10.3854C16.9966 11.3771 16.4169 14.7905 16.3285 16.3732C14.8797 13.9561 12.0103 13.5978 10.7567 13.7208Z"
        fill={color}
      />
      <path
        d="M7.92764 10.7999C10.3447 13.1835 14.5614 12.3167 16.3676 11.5854C16.7883 7.38506 14.2116 4.76055 12.8707 3.97334C12.0192 5.63715 13.3358 8.84944 14.1005 10.2476C11.5783 8.91404 8.93432 10.0601 7.92764 10.7999Z"
        fill={color}
      />
      <path
        d="M2.77148 9.09687C5.74865 10.7706 9.57984 8.84466 11.1233 7.67247C10.39 3.5133 7.19363 1.64857 5.68709 1.2361C5.31715 3.06066 7.45778 5.81796 8.57434 6.96854C5.78049 6.3346 3.54166 8.12328 2.77148 9.09687Z"
        fill={color}
      />
    </svg>
  );
};
