import { makeStyles, Grid, createStyles } from '@material-ui/core';
import { PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  onToast: boolean;
  setOnToast(state: boolean): void;
}

export default function ToastPageMsg({ children, onToast, setOnToast }: PropsWithChildren<Props>) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (onToast && !open) {
      setOpen(true);
      setOnToast(false);

      setTimeout(() => {
        setOpen(false);
      }, 2000);
    } else {
      setOnToast(false);
    }
  }, [onToast]);

  return (
    <>
      {open && (
        <Grid className={classes.toast} container justifyContent={'center'} alignItems={'center'}>
          <p>{children}</p>
        </Grid>
      )}
    </>
  );
}

const useStyle = makeStyles(() =>
  createStyles({
    toast: {
      position: 'fixed',
      left: 'calc(50vw - 115px)',
      top: 'calc(50vh - 40px)',
      width: 230,
      minHeight: 40,
      opacity: 0,
      borderRadius: 5,
      background: 'rgba(0,0,0,0.75)',
      zIndex: 10000,
      animation: '$myEffect 2000ms ease',
      padding: '10px 15px',
      '& p': {
        fontSize: 14,
        lineHeight: 1.2,
        letterSpacing: -0.54,
        color: '#fff',
        textAlign: 'center',
      },
    },
    '@keyframes myEffect': {
      '0%': {
        opacity: 0,
      },
      '10%': {
        opacity: 0,
      },
      '20%': {
        opacity: 1,
      },
      '90%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  }),
);
