const BattleContractFireIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.782 11.8202C17.3588 8.31674 12.2917 8.12783 13.5155 3.03586C13.6061 2.65804 13.1801 2.36609 12.8356 2.56358C9.54517 4.40116 7.17931 8.0849 9.16446 12.9107C9.32762 13.3057 8.83813 13.6749 8.48461 13.4173C6.84392 12.2409 6.67169 10.5493 6.81673 9.33857C6.87111 8.89206 6.25472 8.67739 5.99185 9.04662C5.37546 9.93965 4.75 11.3822 4.75 13.5547C5.09445 18.3633 9.38201 19.8402 10.923 20.0292C13.1257 20.2953 15.5097 19.9089 17.2229 18.4234C19.1083 16.7662 19.7972 14.1214 18.782 11.8202Z"
        fill="#FF4800"
      />
    </svg>
  );
};

export default BattleContractFireIcon;
