import { makeStyles, Grid, createStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import PCImg from '../asset/images/png/pc-mode-lastest.png';
import PCFooter from '../asset/images/png/pc-mode-footer-new.png';

export default function InoutPCMode() {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        className={classes.content}
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <img src={PCImg} alt="QR code" />
        <Grid className={classes.video}>
          <ReactPlayer
            url={'https://youtu.be/5qWSBWywDxU'}
            playing={true}
            muted={true}
            width={'100%'}
            height={'100%'}
            controls={true}
          />
        </Grid>
      </Grid>
      <Grid className={classes.footer}>
        <img src={PCFooter} alt={'footer'} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100vh',
      background: '#fff',
    },
    content: {
      width: '83%',
      // maxWidth: 1024,
      maxWidth: 1440,
      height: 'auto',
      // maxHeight: 768,
      maxHeight: 1080,
      '& img': {
        width: '42%',
        objectFit: 'contain',
      },
    },
    video: {
      width: '45vw',
      height: '25vw',
      // maxWidth: 462.1,
      maxWidth: 647,
      // maxHeight: 260,
      maxHeight: 364,
    },

    footer: {
      marginTop: 70,
      width: '83%',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
  }),
);
