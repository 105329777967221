import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';
dayjs.extend(utc);
dayjs.extend(timezone);

function leftPad(value: number) {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

export function toStringByFormatting(source: Date, delimiter = '-') {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  return [year, month, day].join(delimiter);
}

export function convertDayName(date: Date) {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  return days[date.getDay()];
}

function deleteZero(value: string) {
  if (Number(value) < 10) {
    return value[1];
  }
  return value;
}

export function excludingZeroDateFormat(formattedDate: string) {
  const [year, month, day] = formattedDate.split('-');
  const monthExcludingZero = deleteZero(month);
  const dayExcludingZero = deleteZero(day);
  return `${monthExcludingZero}.${dayExcludingZero}`;
}

export const translateTextDate = (date: string, format: string) => {
  return (date === '' ? dayjs() : dayjs(date)).format(format);
};

export function timeReverseCalculator(initalTime: string, endTime: string) {
  const initialDate = dayjs(initalTime).tz('Asia/Seoul');
  const currentDate = dayjs().tz('Asia/Seoul');
  const endDate = dayjs(endTime).tz('Asia/Seoul');
  const subtractedSeconds = currentDate.diff(initialDate, 'second');
  const fastingSeconds = endDate.diff(initalTime, 'second');
  const fastingTime = endDate.diff(initalTime, 'hour');
  const subtractedDay = endDate.diff(initalTime, 'day');

  return {
    subtractedDay,
    subtractedSeconds,
    fastingSeconds,
    fastingTime,
  };
}
