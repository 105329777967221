const BattleContractCoinIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="12.8537"
        cy="13.1357"
        rx="9.49988"
        ry="4.45307"
        transform="rotate(-39.0318 12.8537 13.1357)"
        fill="#FAA300"
      />
      <path
        d="M3.60474 16.8115L18.3637 4.84639L20.2332 7.15248L5.47429 19.1176L3.60474 16.8115Z"
        fill="#FAA300"
      />
      <ellipse
        cx="10.9841"
        cy="10.8291"
        rx="9.49988"
        ry="4.45307"
        transform="rotate(-39.0318 10.9841 10.8291)"
        fill="#FFBF00"
      />
    </svg>
  );
};

export default BattleContractCoinIcon;
