import { useEffect, useState } from 'react';
import { getBattleContractInfo } from '../../../apis/battleApi';
import { BattleContractInfo } from '../../../model/Battle';

const useBattleContractInfo = (battleCode: string) => {
  const [loading, setLoading] = useState(true);
  const [battleInfo, setBattleInfo] = useState<BattleContractInfo>({
    battleContractMain: {
      battleInviteCode: '',
      battleStatus: 0,
      updatedAt: '',
      battleName: '',
      battleStartAt: '',
      battleEndAt: '',
      battleMoney: 0,
      battlePenalty: '',
    },
    battleContractContent: [],
    sub: {
      dayTerm: '',
      dayDiff: 0,
    },
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await getBattleContractInfo(battleCode);
      if (data.status) {
        setBattleInfo(data.data.battleContractInfo);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    })();
  }, []);

  return {
    loading,
    battleInfo,
  };
};

export { useBattleContractInfo };
