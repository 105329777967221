import { makeStyles, Grid, createStyles } from '@material-ui/core';

interface SpinnerProps {
  height?: string;
}

export default function Spinner({ height }: SpinnerProps) {
  const classes = useStyles();
  const dotList = [0, 1, 2, 3];

  return (
    <Grid
      className={classes.container}
      container
      justifyContent={'center'}
      alignItems={'center'}
      style={height ? { height: height } : {}}
    >
      <Grid
        className={classes.content}
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {dotList.map((item, index) => (
          <Dot index={item} key={index} />
        ))}
      </Grid>
    </Grid>
  );
}

interface Props {
  index: number;
}

function Dot({ index }: Props) {
  const classes = useStyles({ index });
  const backgroundColor = (() => {
    switch (index) {
      case 0:
        return '#ff5367';
      case 1:
        return '#ff97b9';
      case 2:
        return '#acf982';
      case 3:
        return '#58f28f';
      default:
        return '#ff5367';
    }
  })();

  return (
    <Grid className={classes.dot} container justifyContent={'center'} alignItems={'center'}>
      <div style={{ background: backgroundColor, animationDelay: `${index / 6}s` }} />
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    content: {
      width: 96,
      height: 21,
    },
    dot: {
      width: 21,
      height: 21,
      '& div': {
        width: 13,
        height: 13,
        borderRadius: '50%',
        background: '#000',
        animation: '$wave 1.2s infinite linear',
      },
    },
    '@keyframes wave': {
      '0%': {
        width: 13,
        height: 13,
      },
      '12.5%': {
        width: 21,
        height: 21,
      },
      '25%': {
        width: 13,
        height: 13,
      },
    },
  }),
);
