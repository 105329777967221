interface Props {
  active?: boolean;
}

export default function FingerPrint({ active = false }: Props) {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3879 4.7094C16.2052 5.61314 11.5116 9.28446 9.25833 14.3772C7.0446 19.3807 5.71226 21.1581 3.42025 22.5401C2.60736 23.0302 1.55102 22.7686 1.06087 21.9557C0.570708 21.1428 0.832341 20.0865 1.64524 19.5963C2.99314 18.7836 3.96787 17.8388 6.11477 12.9864C8.81759 6.8775 14.4481 2.43016 20.7974 1.323C27.2375 0.200018 34.244 2.54146 39.5047 9.65154C43.8245 15.4899 43.9004 21.9779 42.3514 27.5444C40.8129 33.0731 37.6419 37.8477 35.1657 40.5916C34.5297 41.2962 33.4429 41.352 32.7382 40.716C32.0335 40.08 31.9778 38.9932 32.6137 38.2885C34.8114 35.8534 37.6724 31.5366 39.0397 26.6229C40.3966 21.7468 40.2522 16.4412 36.7414 11.6961C32.2137 5.57687 26.48 3.82148 21.3879 4.7094Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5941 17.4172C31.2821 14.2946 28.4151 12.2828 26.1299 11.4207C25.2417 11.0857 24.7933 10.0941 25.1284 9.20593C25.4634 8.31778 26.455 7.86939 27.3431 8.20442C30.2221 9.29043 33.981 11.8439 35.7632 16.0857C37.5963 20.4486 37.1568 26.1467 32.4454 33.0564C31.9106 33.8407 30.8413 34.0429 30.0571 33.5082C29.2728 32.9734 29.0705 31.9041 29.6053 31.1199C33.8569 24.8845 33.8552 20.4188 32.5941 17.4172Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2182 9.39802C21.4635 10.315 20.919 11.2573 20.002 11.5026C16.6945 12.3874 14.0184 15.4312 12.5614 19.3453C11.1613 23.1066 7.95058 26.4629 6.52746 27.6899C5.80852 28.3097 4.72324 28.2293 4.10342 27.5104C3.48359 26.7915 3.56394 25.7062 4.28287 25.0864C5.5111 24.0274 8.23042 21.1266 9.33986 18.1462C11.0198 13.6331 14.3425 9.45821 19.1137 8.18184C20.0307 7.93653 20.9729 8.48103 21.2182 9.39802Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5213 17.0406C20.4914 17.6162 19.7349 18.6896 19.3861 19.5157C19.0168 20.3902 18.0086 20.7997 17.1341 20.4305C16.2597 20.0612 15.8501 19.053 16.2193 18.1785C16.771 16.8721 17.9611 15.0925 19.8443 14.04C21.861 12.9128 24.448 12.7513 27.3254 14.4213C29.2206 15.5213 30.4238 17.1707 30.8528 19.2162C31.2679 21.1957 30.9301 23.3987 30.0807 25.6635C28.3861 30.1814 24.4563 35.4775 18.7261 40.9452C18.0394 41.6005 16.9514 41.575 16.2961 40.8883C15.6408 40.2015 15.6663 39.1136 16.3531 38.4583C21.9183 33.148 25.4293 28.2763 26.8621 24.4563C27.5763 22.5521 27.7238 21.0437 27.4884 19.9217C27.267 18.8657 26.6839 18.0235 25.5999 17.3944C23.7105 16.2978 22.4177 16.5396 21.5213 17.0406Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2829 19.7956C24.1881 20.0815 24.6901 21.047 24.4042 21.9522C23.3675 25.2339 21.3103 28.5539 19.2403 31.3185C17.1575 34.1002 14.9744 36.4345 13.573 37.7562C12.8824 38.4075 11.7946 38.3757 11.1433 37.6851C10.4921 36.9945 10.5239 35.9067 11.2145 35.2554C12.4769 34.0648 14.5276 31.8772 16.4886 29.2582C18.4624 26.6221 20.259 23.6626 21.1263 20.9168C21.4122 20.0116 22.3778 19.5096 23.2829 19.7956Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.937 23.4314C15.7918 23.8441 16.1502 24.8716 15.7375 25.7265C14.9715 27.313 13.7114 28.8649 12.5298 30.116C11.3324 31.3839 10.1173 32.4454 9.33902 33.0528C8.59072 33.6369 7.51065 33.5037 6.9266 32.7554C6.34255 32.0071 6.4757 30.9271 7.224 30.343C7.87086 29.8381 8.95679 28.8929 10.0307 27.7558C11.1203 26.602 12.1017 25.3507 12.6419 24.2319C13.0546 23.3771 14.0822 23.0187 14.937 23.4314Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0894 34.6026C29.8209 35.2075 29.9235 36.2909 29.3185 37.0224C28.9044 37.5232 27.8793 38.7359 27.2465 39.3755C26.5789 40.0502 25.4906 40.0561 24.8158 39.3884C24.141 38.7208 24.1352 37.6326 24.8028 36.9578C25.3131 36.4421 26.2471 35.3426 26.6696 34.8317C27.2745 34.1002 28.3579 33.9976 29.0894 34.6026Z"
        fill={active ? '#ff4800' : '#F1B417'}
      />
    </svg>
  );
}
