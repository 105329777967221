import { useEffect } from 'react';
import { deviceOs } from '../../module/helper';
import { APPSTOREURL, GOOGLESTOREURL } from '../../shared/link';

export default function StoreRedirect() {
  useEffect(() => {
    if (deviceOs === 'ios') {
      window.open(APPSTOREURL, '_blank');
    } else {
      window.open(GOOGLESTOREURL, '_blank');
    }
  }, []);

  return <></>;
}
