import { Grid, makeStyles, createStyles } from '@material-ui/core';
import BayTreeIcon from '../../../asset/images/icon/BayTreeIcon';
import OnboardingImg01 from '../../../asset/images/png/battle-onboarding-contract-01.png';
import OnboardingImg02 from '../../../asset/images/png/battle-onboarding-contract-02.png';
import TextBalloon from '../../../asset/images/png/battle-contract-open-balloon.png';
import HumanImg from '../../../asset/images/png/battle-contract-open-human.png';
import ToastPageMsg from '../../../components/elements/ToastPageMsg';
import { useState } from 'react';
import Fire from '../../../asset/images/icon/Fire';
import { deviceOs } from '../../../module/helper';

interface Props {
  battleCode: string;
  isContracting: boolean;
}

export default function ContractOnboarding({ battleCode, isContracting }: Props) {
  const classes = useStyles();
  const [onToast, setOnToast] = useState(false);
  const copyCodeInClipboard = async () => {
    const data = await navigator.clipboard.writeText(battleCode);
    setOnToast(true);
  };

  return (
    <Grid className={classes.wrapper}>
      <ToastPageMsg onToast={onToast} setOnToast={setOnToast}>
        초대 코드 복사 완료
      </ToastPageMsg>
      {isContracting && (
        <Grid
          className={classes.battleCodeContainer}
          container
          direction={'column'}
          alignItems={'center'}
        >
          <Grid className={classes.balloon}>
            <img src={TextBalloon} alt={'text balloon'} />
          </Grid>
          <Grid className={classes.humanImg}>
            <img src={HumanImg} alt={'human'} />
          </Grid>
          <p className={classes.battleCodeText}>{battleCode}</p>
          {deviceOs !== 'android' && (
            <Grid
              className={classes.battleCodeButton}
              container
              justifyContent={'center'}
              alignItems={'center'}
              onClick={copyCodeInClipboard}
            >
              <p>초대 코드 복사하기</p>
            </Grid>
          )}
          <Grid
            className={classes.subInfoFire}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Fire />
            <p>배틀 탭에서 입력!</p>
          </Grid>
        </Grid>
      )}

      <Grid className={classes.header} container justifyContent={'center'} alignItems={'center'}>
        <BayTreeIcon direction={'left'} color={'#4B5D78'} />
        <p>이렇게 승패를 가려요</p>
        <BayTreeIcon direction={'right'} color={'#4B5D78'} />
      </Grid>
      <Grid className={classes.ImgBox1}>
        <img src={OnboardingImg01} alt={'onboarding01'} />
      </Grid>
      <p className={classes.title}>체중 인증샷 공유하기</p>
      <p className={classes.description}>
        배틀 마지막 날 체중 측정한 사진을
        <br />
        채팅방에 올려서 승패를 정해주세요
      </p>
      <Grid className={classes.header} container justifyContent={'center'} alignItems={'center'}>
        <BayTreeIcon direction={'left'} color={'#4B5D78'} />
        <p>내기 금액 지급 방법</p>
        <BayTreeIcon direction={'right'} color={'#4B5D78'} />
      </Grid>
      <Grid className={classes.ImgBox2}>
        <img src={OnboardingImg02} alt={'onboarding01'} />
      </Grid>
      <p className={classes.title}>진 사람이 편한 방법으로 주기</p>
      <p className={classes.description}>
        계좌이체, 간편 송금, 현금 등
        <br />
        편한 방법으로 친구에게 지급해 주세요
      </p>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '74px 0 100px',
      background: '#252F40',
    },
    header: {
      '& p': {
        fontFamily: 'Cafe24 Ssurround',
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '120%',
        letterSpacing: -0.44,
        color: '#fff',
        margin: '0 12px',
      },
    },
    ImgBox1: {
      width: '100%',
      maxWidth: 267,
      margin: '40px 0 34px',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    ImgBox2: {
      width: '100%',
      maxWidth: 267,
      margin: '36px 0',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    title: {
      width: '100%',
      fontSize: 22,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: -0.44,
      textAlign: 'center',
      color: '#fff',
      paddingBottom: 15,
    },
    description: {
      width: '100%',
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: -0.44,
      textAlign: 'center',
      color: '#697C9A',
      paddingBottom: 74,
    },
    battleCodeContainer: {
      paddingBottom: 74,
    },
    balloon: {
      width: 114,
      height: 34,
      marginBottom: 15,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    humanImg: {
      width: 54,
      height: 54,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    battleCodeText: {
      fontSize: 45,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.46,
      color: '#fff',
      padding: '16px 0 21px',
    },
    battleCodeButton: {
      width: 255,
      height: 54,
      borderRadius: 120,
      background: '#007AFF',
      '& p': {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: -0.62,
        color: '#fff',
      },
    },
    subInfoFire: {
      marginTop: 20,
      '& p': {
        paddingLeft: 5,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: -0.44,
        color: '#fff',
      },
    },
  }),
);
