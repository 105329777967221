import axios, { AxiosRequestConfig } from 'axios';
import { BATTLEURL } from '../shared/link';

export async function get<T>(url: string, config?: AxiosRequestConfig) {
  return axios.get<T>(url, config);
}

export async function post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return axios.post<T>(url, data, config);
}

export async function put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return axios.put<T>(url, data, config);
}

export async function patch<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return axios.patch<T>(url, data, config);
}

export async function del<T>(url: string, config?: AxiosRequestConfig) {
  return axios.delete<T>(url, config);
}

interface SuccessWebResponse<T> {
  data: T;
  error: false;
}
interface ErrorWebResponse {
  error: true;
  message: string;
  code: string;
}
export type WebResponse<T> = SuccessWebResponse<T> | ErrorWebResponse;

export const battleAxios = axios.create({
  baseURL: BATTLEURL,
});
