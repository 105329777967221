const BattleContractPeriodIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="16" height="16" rx="3" fill="#EFF0F2" />
      <path
        d="M4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V9.33333H4V7Z"
        fill="#007AFF"
      />
    </svg>
  );
};

export default BattleContractPeriodIcon;
