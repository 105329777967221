import { makeStyles, Grid, createStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import theme from '../../../shared/theme';

export default function FirstVideo() {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container alignItems={'center'}>
      <ReactPlayer
        url={'https://www.youtube.com/watch?v=5qWSBWywDxU'}
        playing={true}
        muted={true}
        width={'100%'}
        height={'56vw'}
        controls={true}
        // light={true}
      />
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      padding: '130px 0',
      background: '#252F40',
    },
  }),
);
